import React from 'react';
import styled from 'styled-components';

const Button = ({ children, href }) => (
  <ButtonContainer href={href}>
    {children}
    <Borders />
  </ButtonContainer>
);

const Borders = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  ::before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    position: absolute;
    right: -12px;
    top: -12px;
    border-top: 4px solid white;
    border-right: 4px solid white;
    transition: all 1200ms ease;
  }
  ::after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    position: absolute;
    left: -12px;
    bottom: -12px;
    border-bottom: 4px solid white;
    border-left: 4px solid white;
    transition: all 1200ms ease;
  }
`;

const ButtonContainer = styled.a`
  position: relative;
  font-family: Orbitron;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: black !important;
  border-bottom: none !important;
  background-color: #fff300;
  display: inline-block;
  padding: 16px 16px 12px;
  transition: all 200ms ease;
  cursor: pointer;
  text-decoration: none;
  :hover {
    transform: scale(1.15);
    ::before {
      left: 6px;
      top: 6px;
      border-color: black;
    }
    :after {
      right: 6px;
      bottom: 6px;
      border-color: black;
    }
    > div {
      ::before {
        right: 6px;
        top: 6px;
        border-color: black;
      }
      :after {
        left: 6px;
        bottom: 6px;
        border-color: black;
      }
    }
  }

  ::before {
    transition: all 600ms ease;
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    position: absolute;
    left: -12px;
    top: -12px;
    border-top: 4px solid white;
    border-left: 4px solid white;
  }
  ::after {
    transition: all 600ms ease;
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    position: absolute;
    right: -12px;
    bottom: -12px;
    border-bottom: 4px solid white;
    border-right: 4px solid white;
  }
`;

export default Button;
