import React from 'react';
import { Link } from 'gatsby';

// components
import SEO from '../components/seo';
import Nav from '../components/Nav';
import Header from '../components/Header';
import Footer from '../components/Footer';

// styled
import Superscript from '../components/styled/Superscript';
import Section from '../components/styled/Section';
import Container from '../components/styled/Container';
import Wrapper from '../components/styled/Wrapper';
import Row from '../components/styled/Row';
import Column from '../components/styled/Column';
import Button from '../components/styled/Button';

// assets
import Speaker from '../assets/speaker.png';

class ProposeTalk extends React.Component {
  render() {
    return (
      <Container>
        <SEO title="Propose a talk" />
        <Nav selected="/propose-talk" />
        <Header />
        <Section noPaddingTop>
          <Wrapper>
            <Superscript small center>
              The CFP is closed.
            </Superscript>
            <Row style={{ marginTop: 60 }}>
              <Column>
                <p>The CFP closed on June 15, 2019.</p>
                <p>
                  Please see <Link to="/speakers">the final line-up of speakers</Link>.
                </p>
              </Column>
              <Column>
                <div style={{ padding: '0 40px' }}>
                  <img src={Speaker} />
                </div>
              </Column>
            </Row>
          </Wrapper>
        </Section>
        <Section noPaddingTop center>
          <Wrapper>
            <Button href="https://www.youtube.com/playlist?list=PLZ66c9_z3umNSrKSb5cmpxdXZcIPNvKGw">
              Watch the talks from 2018
            </Button>
          </Wrapper>
        </Section>

        <Section noPaddingTop dotDetail="right">
          <Wrapper>
            <Superscript>What last year's speakers said</Superscript>
            <p>
              <strong />
              &nbsp; I felt the team made a big effort to create a comfortable
              and welcoming atmosphere which I appreciated a lot.
              <br />
              <em>— Jessica Jordan, Assert(js) Speaker</em>
            </p>
            <p>
              <strong />
              &nbsp; I thought the conference was great and extremely well
              organized.
              <br />
              <em>— Mary Snow, Assert(js) Speaker</em>
            </p>
            <p>
              <strong />
              &nbsp; Now that I am back in Boston I must say: organizers of
              @assertjs from @ok_grow did an amazing job. Both speakers and the
              audience were 100% taken care of. 👏 1000 times over.
              <br />
              <em>— Gleb Bahmutov, Assert(js) Speaker</em>
            </p>
            <p>
              <strong />
              &nbsp; “Back home from the inaugural Assert(js) and it was a great
              first year for the conference. Was humbled by the reaction to my
              talk...but mostly just thrilled to have a space to have
              nuanced/advanced conversations about JavaScript testing.”
              <br />
              <em>— Justin Searls, Assert(js) Speaker</em>
            </p>
          </Wrapper>
        </Section>
        <Footer />
      </Container>
    );
  }
}

export default ProposeTalk;
